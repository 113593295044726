import React, { memo, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

function SuneditorComponent(props) {
  const { ipValue = "", fieldName, setEditorValue } = props;
  console.log(ipValue);
  
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      setOptions={{
        height: 450,
        buttonList: [
          ["undo", "redo"],
          ["bold", "italic", "underline"],
          ["font", "fontSize", "formatBlock"],
          ["paragraphStyle"],
          ["removeFormat"],
          "/",
          ["align", "horizontalRule", "list", "lineHeight"],
          ["link", "image", "video"],
          ["fullScreen", "showBlocks", "codeView"],
        ],
      }}
      onChange={(content) => {
        setEditorValue(fieldName, content);
      }}
      defaultValue={ipValue}
    />
  );
}

export default memo(SuneditorComponent);
