import axiosInstance from "./AxiosInstance";

export function getProfile(id) {
  return axiosInstance.get(`recruiter/candidates/${id}`);
}

export function addNotes(type, data) {
  let cadidateType = "";
  if (type === "A") {
    cadidateType = "applicant";
  } else if (type === "G") {
    cadidateType = "unlock";
  } else {
    return false;
  }
  return axiosInstance.post(
    `recruiter/candidates/${cadidateType}/add-note`,
    data
  );
}

export function getNotes(type, candidate_id) {
  let data = {};
  let cadidateType = {};
  if (type === "A") {
    data = { id: candidate_id };
    cadidateType = "applicant";
  } else if (type === "G") {
    data = { candidate_id };
    cadidateType = "unlock";
  } else {
    return false;
  }
  return axiosInstance.post(
    `recruiter/candidates/${cadidateType}/get-notes`,
    data
  );
}

// export function updateStatus(type, data) {
//   let cadidateType = type === "unlock" ? "unlock" : "applicant";
//   return axiosInstance.get(
//     `recruiter/candidates/${cadidateType}/profile-status`,
//     data
//   );
// }
