import React from "react";
import moment from "moment";

export default function NotificationItemComponent({ item }) {
  const { is_read_by_recruiter, message, created_at = "" } = item;
  return (
    <li style={{ backgroundColor: is_read_by_recruiter ? "#F6EAEA" : "none" }}>
      <div className="timeline-panel p-2">
        <div className="media-body">
          <h5 className="mb-1">{message}</h5>
          <small className="d-block">
            {created_at ? moment(created_at).fromNow() : ""}
          </small>
        </div>
      </div>
    </li>
  );
}
