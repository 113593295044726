import React, { useEffect } from "react";

export default function CommentComponent(props) {
  const { setFinalStatus, comment = {}, name = "NA" } = props;

  useEffect(() => {
    setFinalStatus(comment?.profile_status);
  }, []);

  return (
    <div className="card shadow p-2 m-2 card-heigth">
      <div className="card-body p-2 m-2">
        <h6 className="text-info mb-1">{comment?.profile_name}</h6>
        <p className="mb-1 fw-normal">{comment?.comment}</p>
        {comment?.profile_status && <h5>Status:- {comment?.profile_status}</h5>}
        <div className="float-end fs-6">{comment?.created_at}</div>
      </div>
    </div>
  );
}
