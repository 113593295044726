import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import swal from "sweetalert";
import { object, string } from "yup";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getProfile,
  updateCompanyProfile,
} from "../../../services/CompanyService";
import { Link } from "react-router-dom";
import { uploadFile } from "../../../services/CommonService";
import SuneditorComponent from "./Common/SuneditorComponent";

let profileSchema = object().shape({
  company_logo: string().required({
    company_logo: "Company Logo is required.",
  }),
  banner_image: string().required({
    banner_image: "Banner Image is required.",
  }),
  about_company: string().required({
    about_company: "About company is required.",
  }),
  why_join_us: string().required({ why_join_us: "Why join us is required." }),
});

export default function CompanyProfile() {
  const [errors, setError] = useState({});

  const [inputData, setInputData] = useState({
    company_logo: "",
    banner_image: "",
    about_company: "",
    why_join_us: "",
    website_link: "",
    wikipedia_link: "",
    facebook_link: "",
    twitter_link: "",
    instagram_link: "",
    linkedin_link: "",
    youTube_link: "",
  });

  const [img, setImg] = useState({
    company_logo: false,
    banner_image: false,
  });

  const companyProfile = useCallback(() => {
    getProfile()
      .then((res) => {
        if (res.data?.data !== undefined) {
          console.log(res.data?.data);

          setInputData(res.data?.data);
          let imgStatus = {
            company_logo: res.data?.data?.company_logo !== "",
            banner_image: res.data?.data?.banner_image !== "",
          };
          setImg(imgStatus);
        }
      })
      .catch((err) => console.log(err?.message));
  }, [setInputData]);

  useEffect(() => {
    console.log("render");
    companyProfile();
  }, []);

  useEffect(() => {
    console.log(inputData);
  }, [inputData]);

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      profileSchema
        .validate(inputData, { strict: false, abortEarly: false })
        .then((resp) => {
          console.log(resp);
          setError({});
          updateCompanyProfile(resp)
            .then((response) => {
              if (response?.data?.code === "success") {
                companyProfile();
                swal("Great Job!", response?.data.message, "success");
              } else {
                swal("Opps", resp?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err.errors.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return null;
          });
          setError(errObj);
        });
    },
    [inputData, companyProfile]
  );

  const handleUploadImage = useCallback(
    (name, file) => {
      uploadFile(file)
        .then((res) => {
          if (res.data?.data) {
            setInputData({
              ...inputData,
              [name]: res.data?.data,
            });
          } else {
            console.log(res.data?.error);
          }
        })
        .catch((err) => console.log(err));
    },
    [inputData, setInputData]
  );

  const onSaveEditorValue = useCallback(
    (name, value) => {
      setInputData({
        ...inputData,
        [name]: value,
      });
    },
    [setInputData, inputData]
  );

  return (
    <Fragment>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Company Profile</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={handleAdd}>
                <div className="add-contact-content">
                  <div className="mb-3 form-group">
                    <label htmlFor="company_logo">Upload Company Logo</label>
                    <input
                      id="company_logo"
                      onChange={(e) => {
                        handleUploadImage("company_logo", e.target.files[0]);
                      }}
                      className="form-control"
                      type="file"
                      name="company_logo"
                      required=""
                    />
                    {errors?.company_logo !== undefined && (
                      <div className="invalid-feedback d-block">
                        {errors?.company_logo}
                      </div>
                    )}
                    {img?.company_logo && (
                      <div className="">
                        <Link
                          to={
                            inputData?.baseUrl +
                            "/uploads/recruiter/" +
                            inputData?.company_logo
                          }
                          className="mt-2 fs-4 text-primary"
                          target="_blank"
                        >
                          See uploaded image
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="banner_image">
                      Company Profile Banner Image
                    </label>
                    <input
                      id="banner_image"
                      onChange={(e) => {
                        handleUploadImage("banner_image", e.target.files[0]);
                      }}
                      className="form-control"
                      type="file"
                      name="banner_image"
                      required=""
                    />
                    {errors?.banner_image !== undefined && (
                      <div className="invalid-feedback d-block">
                        {errors?.banner_image}
                      </div>
                    )}
                    {img?.banner_image && (
                      <div className="">
                        <Link
                          to={
                            inputData?.baseUrl +
                            "/uploads/recruiter/" +
                            inputData?.banner_image
                          }
                          className="mt-2 fs-4 text-primary"
                          target="_blank"
                        >
                          See uploaded image
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="about_company">Company Overview</label>
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={inputData?.about_company}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        setInputData({
                          ...inputData,
                          about_company: data,
                        });
                      }}
                      onBlur={(event, editor) => {
                        //console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        //console.log("Focus.", editor);
                      }}
                    /> */}
                    {inputData?.about_company !== "" && (<SuneditorComponent
                      setEditorValue={onSaveEditorValue}
                      fieldName="about_company"
                      ipValue={inputData?.about_company}
                    />)}
                    {errors?.about_company !== undefined && (
                      <div className="invalid-feedback d-block">
                        {errors?.about_company}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="why_join_us">Why Join Us?</label>
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={inputData?.why_join_us}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        // console.log({ event, editor, data });
                        setInputData({
                          ...inputData,
                          why_join_us: data,
                        });
                      }}
                      onBlur={(event, editor) => {
                        //console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        //console.log("Focus.", editor);
                      }}
                    /> */}
                    {inputData?.why_join_us !== "" && (
                      <SuneditorComponent
                        setEditorValue={onSaveEditorValue}
                        fieldName="why_join_us"
                        ipValue={inputData?.why_join_us}
                      />
                    )}
                    {errors?.why_join_us !== undefined && (
                      <div className="invalid-feedback d-block">
                        {errors?.why_join_us}
                      </div>
                    )}
                  </div>
                  <h6>Follow Us</h6>
                  <div className="mb-3 form-group">
                    <label htmlFor="website_link">Website Link</label>
                    <input
                      id="website_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          website_link: e.target.value,
                        })
                      }
                      value={inputData?.website_link}
                      className="form-control"
                      placeholder="Website Link"
                      type="text"
                      name="website_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="wikipedia_link">Wikipedia Link</label>
                    <input
                      id="wikipedia_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          wikipedia_link: e.target.value,
                        })
                      }
                      value={inputData?.wikipedia_link}
                      className="form-control"
                      placeholder="Wikipedia Link"
                      type="text"
                      name="wikipedia_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="facebook_link">Facebook Link</label>
                    <input
                      id="facebook_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          facebook_link: e.target.value,
                        })
                      }
                      value={inputData?.facebook_link}
                      className="form-control"
                      placeholder="Facebook Link"
                      type="text"
                      name="facebook_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="twitter_link">Twitter/X Link</label>
                    <input
                      id="twitter_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          twitter_link: e.target.value,
                        })
                      }
                      value={inputData?.twitter_link}
                      className="form-control"
                      placeholder="Twitter/X Link"
                      type="text"
                      name="twitter_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="instagram_link">Instagram Link</label>
                    <input
                      id="instagram_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          instagram_link: e.target.value,
                        })
                      }
                      value={inputData?.instagram_link}
                      className="form-control"
                      placeholder="Instagram Link"
                      type="text"
                      name="instagram_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="linkedin_link">LinkedIn Link</label>
                    <input
                      id="linkedin_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          linkedin_link: e.target.value,
                        })
                      }
                      value={inputData?.linkedin_link}
                      placeholder="LinkedIn Link"
                      className="form-control"
                      type="text"
                      name="linkedin_link"
                      required=""
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="youTube_link">YouTube Link</label>
                    <input
                      id="youTube_link"
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          youTube_link: e.target.value,
                        })
                      }
                      value={inputData?.youTube_link}
                      className="form-control"
                      placeholder="YouTube Link"
                      type="text"
                      name="youTube_link"
                      required=""
                    />
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      <i className="fa fa-save"></i> Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
