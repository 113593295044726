import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import SearchInputComponent from "./Common/SearchInputComponent";
import {
  downloadResume,
  unlockProfileList,
} from "../../../services/UnlockProfileService";
import { downloadCSV } from "../../../helpers/dataTableHelper";
import { getPublishedJob } from "../../../services/JobsService";

export default function FavouriteProfiles() {
  const tableClm = useMemo(() => {
    return [
      {
        name: "View Action",
        button: "true",
        cell: (row) => (
          <>
            <Link
              target="_blank"
              to={`/${row?.id}/candidate-profile`}
              className="btn btn-primary btn-sm light mr-2"
            >
              <i className="fa fa-eye"></i>
            </Link>
            <button
              //target="_blank"
              //to={`/${row?.id}/candidate-profile`}
              onClick={() => handleDownload(row?.id)}
              className="ms-2 btn btn-primary btn-sm light mr-2"
            >
              <i className="fa fa-download"></i>
            </button>
          </>
        ),
      },
      {
        name: "ID",
        selector: (row) => row.id,
      },
      {
        name: "Name",
        selector: (row) => row.preferred_name,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Phone",
        selector: (row) => row.ph_country_code + " " + row.phone_number,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Out at Work",
        selector: (row) => row.out_at_work,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Identify As",
        selector: (row) => row.identify_name,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Current City",
        selector: (row) => row.city_name,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "About Yourself",
        selector: (row) => row.about_yourself,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Qualification",
        selector: (row) => row.highest_education,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Year of Passing",
        selector: (row) => row.highest_education_passing_year,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "College",
        selector: (row) => row.institution_name,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Apply for internship",
        selector: (row) => row.interested_in_internships,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Over 16 years of age",
        selector: (row) => row.over_eighteen_years,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Internship month(s)",
        selector: (row) => row.internship_available_months,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Aggregate percentage",
        selector: (row) => row.aggregate_percentage,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Experience",
        selector: (row) => row.total_experience,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Current Employer",
        selector: (row) => row.current_previous_company,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Current Role",
        selector: (row) => row.current_profile_role,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Skills",
        selector: (row) => row.skills,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "CTC",
        selector: (row) => row.current_salary,
        wrap: true,
        minWidth: "200px",
      },
    ];
  }, []);

  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [columns, setColumn] = useState(tableClm);

  const [filterText, setFilterText] = useState("");
  const [jobId, setJobId] = useState("");

  const fetchUsers = useCallback(
    (page, size = perPage) => {
      setLoading(true);
      let params = {
        page: page,
        limit: size,
        sort_by: "created_at",
        sort_direction: "DESC",
        keyword: filterText,
        job_id: jobId,
      };

      unlockProfileList(params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
        });
    },
    [unlockProfileList, perPage, filterText, jobId]
  );

  const fetchJobs = useCallback(() => {
    getPublishedJob()
      .then((res) => {
        setJobs(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, [getPublishedJob, setJobs]);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, fetchUsers]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleDownload = useCallback((candidate_id) => {
    downloadResume({ candidate_id: String(candidate_id) })
      .then((res) => {
        let { data } = res.data;
        window.open(
          data?.baseUrl + data?.resume_file,
          "_blank",
          "rel=noopener noreferrer"
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);
  console.log(jobs);

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Unlock Profiles</h4>

        <select
          id="min_experience"
          onChange={(e) => setJobId(e.target.value)}
          className="btn btn-warning btn-sm me-2"
          type="text"
          name="min_experience"
        >
          <option value="">Select Job</option>
          {jobs.length > 0 &&
            jobs.map((job, i) => {
              return (
                <option value={job?.id} key={i}>
                  {job?.job_title}
                </option>
              );
            })}
        </select>

        <button
          onClick={() => downloadCSV(data)}
          className="btn btn-danger btn-sm "
        >
          <i className="fas fa-file-csv me-1"></i>Export
        </button>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
              onChangePage={handlePageChange}
              selectableRows
              persistTableHead
              subHeader
              subHeaderComponent={
                <SearchInputComponent setFilterText={setFilterText} />
              }
              onSelectedRowsChange={({ selectedRows }) => {
                //console.log(selectedRows)
                //setSelectedRows(selectedRows);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
