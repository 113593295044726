import React, { Fragment, useEffect } from "react";
import CounterCard from "./Dashboard/CounterCard";
import { useDispatch, useSelector } from "react-redux";
import { callGetDashboardCount } from "../../../store/actions/DashboardActions";

export default function Dashboard() {
  const counts = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callGetDashboardCount());
  }, []);

  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Your Applicants</h2>
          <div className="row">
            <CounterCard
              title={counts?.total_unlock_quota}
              subtitle="Total Unlock Allotted"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={counts?.general_unlock_quota}
              subtitle="Database Unlock Allotted"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={counts?.applicants_unlock_quota}
              subtitle="Applicant Unlock Allotted"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={counts?.total_unlocked_remaining}
              subtitle="Total Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={counts?.general_unlocked_remaining}
              subtitle="Database Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={counts?.applicants_unlocked_remaining}
              subtitle="Applicant Unlocked"
              link="/unlock-profile"
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={
                counts?.total_unlock_quota - counts?.total_unlocked_remaining
              }
              subtitle="Total Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="success"
            />
            <CounterCard
              title={
                counts?.general_unlock_quota -
                counts?.general_unlocked_remaining
              }
              subtitle="Database Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="info"
            />
            <CounterCard
              title={
                counts?.applicants_unlock_quota -
                counts?.applicants_unlocked_remaining
              }
              subtitle="Applicant Balance"
              link=""
              iconClass="fas fa-unlock"
              cssClass="warning"
            />

            <CounterCard
              title={counts?.start_subscription}
              subtitle="Subscription Start Date"
              link=""
              iconClass="fas fa-calendar-check"
              cssClass="success"
            />
            <CounterCard
              title={counts?.expire_date}
              subtitle="Subscription End Date"
              link=""
              iconClass="fas fa-calendar-times"
              cssClass="danger"
            />
            <CounterCard
              title={counts?.expire_date_days}
              subtitle="Balance Days"
              link=""
              iconClass="fas fa-clock"
              cssClass="warning"
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Your Jobs</h2>
          <div className="row">
            <CounterCard
              title={counts?.total_jobs}
              subtitle="Total Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard-list"
              cssClass="primary"
            />
            <CounterCard
              title={counts?.live_on_site}
              subtitle="Live Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard-check"
              cssClass="success"
            />
            <CounterCard
              title={counts?.remaining_jobs}
              subtitle="Job Post Balance"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="warning"
            />
            <CounterCard
              title={counts?.applicants}
              subtitle="Total Applicants"
              link=""
              iconClass="fas fa-users"
              cssClass="dark"
            />
            <CounterCard
              title={counts?.draft_jobs}
              subtitle="Draft jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="info"
            />
            <CounterCard
              title={counts?.hot_jobs}
              subtitle="Hot Jobs"
              link="/manage-jobs"
              iconClass="fas fa-clipboard"
              cssClass="warning"
            />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Sub Recruiters</h2>
          <div className="row">
            <CounterCard
              title={counts?.sub_recruiter_quota}
              subtitle="Total Sub Recruiters Allotted"
              link="/manage-sub-recruiter"
              iconClass="fas fa-user-tag"
              cssClass="info"
            />
            <CounterCard
              title={counts?.subrecruiter_remaining_quota}
              subtitle="Remaining Sub Recruiters"
              link="/manage-sub-recruiter"
              iconClass="fas fa-user"
              cssClass="warning"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
