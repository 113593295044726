import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axiosInstance from "../../../services/AxiosInstance";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { object, string } from "yup";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  addJob,
  getJob,
  getLocations,
  getSkills,
  updateJob,
  updateJobStatus,
} from "../../../services/JobsService";
import MultiSelect from "./Jobs/MultiSelect";
import { uploadFile } from "../../../services/CommonService";
import SuneditorComponent from "./Common/SuneditorComponent";

let jobSchema = object().shape({
  job_title: string().required({ job_title: "Job title is required." }),
  company_id: string().required({ company_id: "Company name is required." }),
  job_locations: string().required({ job_locations: "Location is required." }),
  job_skills: string().required({ job_skills: "Skills are required." }),
  min_experience: string().required({
    min_experience: "Minimum experience is required.",
  }),
  max_experience: string().required({
    max_experience: "Maximum experience is required.",
  }),
  job_position: string().required({
    job_position: "Job position is required.",
  }),
  job_ctc: string().required({ job_ctc: "Job CTC is required." }),
  job_details: string().required({ job_details: "Job details is required." }),
  job_sector: string().required({ job_sector: "Job sector is required." }),
  job_types: string().required({ job_types: "Job type is required." }),
  application_deadline: string().required({
    application_deadline: "Application deadline is required.",
  }),
  profile_image: string().required({ profile_image: "Image is required." }),
});

let jobUpdateSchema = object().shape({
  job_title: string().required({ job_title: "Job title is required." }),
  company_id: string().required({ company_id: "Company name is required." }),
  job_locations: string().required({ job_locations: "Location is required." }),
  job_skills: string().required({ job_skills: "Skills are required." }),
  min_experience: string().required({
    min_experience: "Minimum experience is required.",
  }),
  max_experience: string().required({
    max_experience: "Maximum experience is required.",
  }),
  job_position: string().required({
    job_position: "Job position is required.",
  }),
  job_ctc: string().required({ job_ctc: "Job CTC is required." }),
  job_details: string().required({ job_details: "Job details is required." }),
  job_sector: string().required({ job_sector: "Job sector is required." }),
  job_types: string().required({ job_types: "Job type is required." }),
  application_deadline: string().required({
    application_deadline: "Application deadline is required.",
  }),
  //profile_image: string().required({ profile_image: "Image is required." }),
});

export default function ManageJobs() {
  
  const optionCount = 50;
  const inputs = useMemo(() => {
    return {
      job_title: "",
      company_id: "",
      min_experience: "",
      max_experience: "",
      job_position: "",
      job_locations: "",
      job_skills: "",
      job_ctc: "",
      job_details: "",
      job_sector: "1",
      job_types: "",
      application_deadline: new Date(),
      profile_image: "",
    };
  }, []);

  const jobTypes = useMemo(() => {
    return [
      { value: 1, label: "Full Time" },
      { value: 2, label: "Part Time" },
      { value: 3, label: "WFH" },
      { value: 4, label: "Intership" },
    ];
  }, []);
  const [addModel, setAddModal] = useState(false);
  const [updateModel, setUpdateModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRow, setSelectedRows] = useState([]);
  const [errors, setError] = useState({});
  const [inputData, setInputData] = useState(inputs);
  const [editData, setEditData] = useState(inputs);

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const fetchUsers = useCallback(
    (page, size = perPage) => {
      setLoading(true);
      let params = {
        page: page,
        limit: size,
        sort_by: "created_at",
        sort_direction: "DESC",
        keyword: "",
      };

      axiosInstance
        .post("recruiter/jobs/list", params)
        .then((res) => {
          setData(res?.data?.data);
          //setTotalRows(res.data.totalRecords);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          //console.log(err);
          setLoading(false);
        });
    },
    [perPage]
  );

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, fetchUsers]);

  const formatOptionList = useCallback((res, label, value, callback) => {
    if (res.length > 0) {
      let optionArray = [];
      res.forEach((obj, i) => {
        optionArray.push({ value: obj[value], label: obj[label] });
      });
      callback(optionArray);
    }
  }, []);

  const fetchPageData = useCallback(() => {
    getSkills()
      .then((res) => {
        formatOptionList(res?.data?.data, "skill_title", "id", setSkills);
      })
      .catch((err) => {
        console.log("Fetch Skills error " + err.message);
      });

    getLocations()
      .then((res) => {
        formatOptionList(res?.data?.data, "city_name", "id", setLocations);
      })
      .catch((err) => {
        console.log("Fetch Location error " + err.message);
      });

    // getJobTypes()
    // .then((res) => {
    //   formatOptionList(res?.data?.data, "city_name", "id", setJobTypes);
    // })
    // .catch((err) => {
    //   console.log('Fetch Location error '+err.message);
    // });
  }, [formatOptionList, setSkills]);

  useEffect(() => {
    fetchPageData();
  }, [fetchPageData]);

  const setSelectedValues = useCallback((value, states, setSelectedFun) => {
    if (value) {
      let valueArray = value.split(",");
      if (valueArray?.length > 0) {
        let selectedArray = states.filter((ele, i) => {
          return valueArray.includes(String(ele?.value));
        });
        setSelectedFun(selectedArray);
      }
    }
  }, []);

  const onEdit = useCallback(
    (row) => {
      setError({});
      getJob(row?.id)
        .then((res) => {
          if (res.data?.data?.id) {
            setSelectedValues(
              res.data?.data?.locations,
              locations,
              setSelectedLocations
            );
            setSelectedValues(
              res.data?.data?.skills,
              skills,
              setSelectedSkills
            );
            setSelectedValues(
              res.data?.data?.job_types,
              jobTypes,
              setSelectedJobTypes
            );
            let editIp = {
              id: res.data?.data?.id,
              job_title: res.data?.data?.job_title,
              company_id: res.data?.data?.company_id,
              min_experience: res.data?.data?.min_experience,
              max_experience: res.data?.data?.max_experience,
              job_position: res.data?.data?.job_position,
              job_locations: res.data?.data?.locations,
              job_skills: res.data?.data?.skills,
              job_ctc: res.data?.data?.job_ctc,
              job_details: res.data?.data?.job_details,
              job_sector: res.data?.data?.job_sector,
              job_types: res.data?.data?.job_types,
              application_deadline: res.data?.data?.application_deadline,
              profile_image: res.data?.data?.profile_image,
            };
            setEditData(editIp);
            setInputData(editIp);
            //console.log(editIp);
            setUpdateModal(true);
          } else {
            console.log(res?.data);
          }
        })
        .catch((err) => console.log(err));
      // console.log(row);
    },
    [
      setEditData,
      setError,
      jobTypes,
      locations,
      setSelectedValues,
      skills,
      setInputData,
    ]
  );

  const handleChangeStatus = useCallback(
    (status) => {
      const jobs = selectedRow.map((c, i) => {
        return c.id;
      });

      if (jobs?.length < 1) {
        swal(
          "Opps",
          `Please select at least 1 job to ${
            status === 0 ? "Inactive" : "Activate"
          }.`,
          "error"
        );
        return false;
      }

      swal({
        title: "Are you sure?",
        text: "Status will be change.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let data = { ids: jobs, job_status: String(status) };
          updateJobStatus(data)
            .then((res) => {
              if (res.data?.code === "success") {
                setToggleCleared(!toggleCleared);
                fetchUsers(currentPage);
                swal("Great", res.data?.message, "success");
              } else {
                swal("Failed", res.data?.message, "error");
              }
            })
            .catch((errors) => {
              console.log(errors);
            });
        }
      });
    },
    [currentPage, fetchUsers, selectedRow, toggleCleared]
  );

  const columns = useMemo(
    () => [
      {
        name: "Job Code",
        selector: (row) => row.id,
      },
      {
        name: "Title",
        selector: (row) => row.job_title,
      },
      {
        name: "Company",
        selector: (row) => row.company_name,
      },
      {
        name: "Location",
        selector: (row) => row.locations,
      },
      {
        name: "Created Time",
        selector: (row) => row.created_at,
      },
      {
        name: "Deadline",
        selector: (row) => row.application_deadline,
      },
      {
        name: "Edit",
        button: "true",
        width: "150px",
        cell: (row) => {
          if (row.verified_status !== 1) {
            return (
              <span
                className="btn btn-sm btn-outline-primary p-2"
                onClick={() => onEdit(row)}
              >
                <i className="fa fa-edit"></i> Edit Profile
              </span>
            );
          }
        },
      },
      {
        name: "Applicants List",
        button: "true",
        width: "150px",
        cell: (row) => (
          <Link
            className="btn btn-sm btn-outline-primary p-2"
            //onClick={() => swal("", "Work in progress", "info")}
            to={`/${row.id}/applicants`}
          >
            <i className="fa fa-eye"></i> Applicants
          </Link>
        ),
      },
      // {
      //   name: "Activation",
      //   button: "true",
      //   cell: (row) => (
      //     <span
      //       className="btn btn-sm btn-outline-primary p-2"
      //       onClick={() =>
      //         handleChangeStatus(row?.id, row?.verified_status === 1 ? 0 : 1)
      //       }
      //     >
      //       <i
      //         className={`fa ${
      //           row?.verified_status === 1 ? "fa-lock" : "fa-unlock"
      //         }`}
      //       ></i>{" "}
      //       {row?.verified_status === 1 ? "Inactive" : "Activate"}
      //     </span>
      //   ),
      // },
      {
        name: "Preview",
        button: "true",
        width: "150px",
        cell: (row) => (
          <a
            className="btn btn-sm btn-outline-primary p-2"
            href={`https://jobs.thepridecircle.com/jobs/job-detail/${row.id}`}
            target="_blank"
          >
            <i className="fa fa-eye"></i> Preview
          </a>
        ),
      },
    ],
    [onEdit, handleChangeStatus]
  );

  const handlePageChange = (page) => {
    fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      jobSchema
        .validate(inputData, { strict: false, abortEarly: false })
        .then((resp) => {
          let skillsArray = (resp?.job_skills).split(",");
          if (skillsArray.length < 5) {
            setError({
              job_skills: "Please select at least 5 skills.",
            });
            return false;
          }
          resp = {
            ...resp,
            application_deadline: moment(resp?.application_deadline).format(
              "yyyy-MM-DD"
            ),
          };
          setError({});
          addJob(resp)
            .then((response) => {
              if (response?.data?.code === "success") {
                setInputData(inputs);
                e.target.reset();
                setAddModal(false);
                swal("Great Job!", response?.data.message, "success");
                fetchUsers(currentPage);
              } else {
                swal("Opps", resp?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err.errors.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return false;
          });
          setError(errObj);
        });
    },
    [currentPage, fetchUsers, inputs, inputData]
  );

  const handleUpdate = useCallback(
    (e) => {
      e.preventDefault();
      jobUpdateSchema
        .validate(inputData, { strict: false, abortEarly: false })
        .then((resp) => {
          console.log(resp);

          let skillsArray = (resp?.job_skills).split(",");
          if (skillsArray.length < 5) {
            setError({
              job_skills: "Please select at least 5 skills.",
            });
            return false;
          }

          resp = {
            ...resp,
            application_deadline: moment(resp?.application_deadline).format(
              "yyyy-MM-DD"
            ),
          };
          
          setError({});
          updateJob(inputData?.id, resp)
            .then((response) => {
              if (response?.data?.code === "success") {
                setEditData(inputs);
                setInputData(inputs);
                setUpdateModal(false);
                swal("Great Job!", response?.data.message, "success");
                fetchUsers(currentPage);
              } else {
                swal("Opps", resp?.data.message, "error");
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err?.errors?.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return false;
          });
          setError(errObj);
        });
    },
    [editData, currentPage, fetchUsers, inputs, setInputData, inputData]
  );

  const selectOptions = useCallback(() => {
    let options = [];
    for (let index = 0; index < optionCount; index++) {
      options.push(
        <option value={index} key={index}>
          {index}
        </option>
      );
    }
    return options;
  }, [optionCount]);

  const handleUploadImage = useCallback(
    (file) => {
      uploadFile(file)
        .then((res) => {
          if (res.data?.data) {
            setInputData({
              ...inputData,
              profile_image: res.data?.data,
            });
          } else {
            console.log(res.data?.error);
          }
        })
        .catch((err) => console.log(err));
    },
    [inputData, setInputData]
  );

  const handleMultiSelect = useCallback(
    (values, inputname) => {
      console.log(values, inputname);

      if (values?.length > 0) {
        let ids = [];
        values.forEach((element, i) => {
          ids.push(element?.value);
        });
        setInputData({
          ...inputData,
          [inputname]: ids.toString(),
        });
      }
    },
    [inputData, setInputData]
  );

  const rowStyle = useMemo(() => {
    return [
      {
        when: (row) => true,
        style: (row) => {
          let clr = "#ffe7e7";

          if (row.is_recruiter_live > 0) {
            clr = "#fdf8b1";
          }

          if (row.is_recruiter_live > 0 && row.verified_status > 0) {
            clr = "#cbffc0";
          }

          return {
            backgroundColor: clr,
          };
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      let errorHtml = Object.keys(errors).map((k, i) => {
        return `<li>${errors[k]}</li>`;
      });
      var ul = document.createElement("ul");
      ul.innerHTML = errorHtml.join("");
      swal({
        title: "Input validation error!",
        icon: "error",
        content: ul,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (!updateModel) {
      setInputData(inputs);
    }
  }, [updateModel, setInputData, inputs]);

  const onSaveEditorValue = useCallback(
    (name, value) => {
      setInputData({
        ...inputData,
        [name]: value,
      });
    },
    [setInputData, inputData]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Jobs</h4>
        <div>
          <Link
            to={"#"}
            onClick={() => setAddModal(true)}
            className="btn btn-info me-3 btn-sm"
          >
            <i className="fas fa-plus-circle me-1"> </i>New Job
          </Link>
          <button
            onClick={() => handleChangeStatus(1)}
            className="btn btn-warning me-3 btn-sm"
          >
            <i className="fas fa-thumbs-up me-1"> </i>Activate Jobs
          </button>

          <button
            onClick={() => handleChangeStatus(0)}
            className="btn btn-danger me-3 btn-sm"
          >
            <i className="fas fa-thumbs-down me-1"> </i>Inactivate Jobs
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              selectableRows
              persistTableHead
              clearSelectedRows={toggleCleared}
              conditionalRowStyles={rowStyle}
              onSelectedRowsChange={({ selectedRows }) => {
                //console.log(selectedRows)
                setSelectedRows(selectedRows);
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        className="modal fade"
        size="lg"
        show={addModel}
        onHide={setAddModal}
        keyboard={false}
        backdrop="static"
      >
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Create New Job</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <form onSubmit={handleAdd}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="mb-3 form-group">
                          <label htmlFor="job_title">
                            Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_title"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_title: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="job_title"
                            required=""
                          />
                          {errors?.job_title !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_title}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="company_id">
                            Company<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="company_id"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                company_id: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="company_id"
                            required=""
                          />
                          {errors?.company_id !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.company_id}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <label>
                            Experience <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-md-6">
                            <div className="input-group mb-3 form-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Min
                              </span>
                              <select
                                id="min_experience"
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    min_experience: e.target.value,
                                  })
                                }
                                className="form-select"
                                type="text"
                                name="min_experience"
                              >
                                {selectOptions()}
                              </select>
                              {errors?.min_experience !== undefined && (
                                <div className="invalid-feedback d-block">
                                  {errors?.min_experience}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group mb-3 form-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Max
                              </span>
                              <select
                                id="max_experience"
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    max_experience: e.target.value,
                                  })
                                }
                                className="form-select"
                                type="text"
                                name="max_experience"
                              >
                                {selectOptions()}
                              </select>
                              {errors?.max_experience !== undefined && (
                                <div className="invalid-feedback d-block">
                                  {errors?.max_experience}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="locations">
                            Locations<span style={{ color: "red" }}>*</span>{" "}
                            <sub>
                              (Please enter a comma-separated list of cities)
                            </sub>
                          </label>
                          <MultiSelect
                            multiple={true}
                            options={locations}
                            onValueSelect={handleMultiSelect}
                            name="job_locations"
                          />
                          {errors?.job_locations !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_locations}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="skills">
                            Skills<span style={{ color: "red" }}>*</span>{" "}
                            <sub>
                              (Please enter a comma-separated list of skills)
                            </sub>
                          </label>
                          <MultiSelect
                            multiple={true}
                            options={skills}
                            onValueSelect={handleMultiSelect}
                            name="job_skills"
                          />
                          {errors?.job_skills !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_skills}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_position">
                            Position<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_position"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_position: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="job_position"
                            required=""
                          />
                          {errors?.job_position !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_position}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_ctc">
                            CTC<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_ctc"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_ctc: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="job_ctc"
                            required=""
                          />
                          {errors?.job_ctc !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_ctc}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_details">
                            Summary<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <SunEditor
                            getSunEditorInstance={getSunEditorInstance}
                            setOptions={{
                              height: 450,
                              buttonList: [
                                ["undo", "redo"],
                                ["bold", "italic", "underline"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                ["removeFormat"],
                                "/",
                                [
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                ],
                                ["link", "image", "video"],
                                ["fullScreen", "showBlocks", "codeView"],
                              ],
                            }}
                            onChange={(content) => {
                              setInputData({
                                ...inputData,
                                job_details: content,
                              });
                            }}
                          /> */}
                          <SuneditorComponent
                            setEditorValue={onSaveEditorValue}
                            fieldName="job_details"
                          />
                          {/* <CKEditor
                            config={{
                              extraPlugins: [
                                function (editor) {
                                  editor.plugins.get(
                                    "FileRepository"
                                  ).createUploadAdapter = (loader) => {
                                    return new uploadAdapter(loader);
                                  };
                                },
                              ],
                            }}
                            editor={ClassicEditor}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              // console.log({ event, editor, data });
                              setInputData({
                                ...inputData,
                                job_details: data,
                              });
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          /> */}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_sector">
                            Job Sector<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              //value=""
                              id="tech"
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  job_sector: 1,
                                })
                              }
                              name="job_sector"
                              defaultChecked={true}
                            />
                            <label className="form-check-label" htmlFor="tech">
                              Tech
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  job_sector: 0,
                                })
                              }
                              id="non-tech"
                              name="job_sector"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="non-tech"
                            >
                              Non-tech
                            </label>
                          </div>
                          {errors?.job_sector !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_sector}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_types">
                            Job Type<span style={{ color: "red" }}>*</span>
                            <sub>
                              (Please enter job types. Example: Full-time,
                              part-time, work from home)
                            </sub>
                          </label>
                          <MultiSelect
                            multiple={true}
                            onValueSelect={handleMultiSelect}
                            name="job_types"
                            options={jobTypes}
                          />
                          {errors?.job_types !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_types}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="application_deadline">
                            Application Deadline
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="form-group">
                            <DatePicker
                              id="application_deadline"
                              selected={
                                inputData?.application_deadline
                                  ? new Date(inputData?.application_deadline)
                                  : new Date()
                              }
                              onChange={(date) =>
                                setInputData({
                                  ...inputData,
                                  application_deadline: date,
                                })
                              }
                              name="application_deadline"
                              className="form-control"
                              dateFormat="Y-MM-dd"
                              minDate={new Date()}
                            />
                          </div>
                          {errors?.application_deadline !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.application_deadline}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="profile_image">
                            Please Upload Your Company Logo (284*117)
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="profile_image"
                            onChange={
                              (e) => handleUploadImage(e.target.files[0])
                              // setInputData({
                              //   ...inputData,
                              //   profile_image: e.target.files[0],
                              // })
                            }
                            className="form-control"
                            type="file"
                            name="profile_image"
                            required=""
                          />
                          {errors?.profile_image !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.profile_image}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div>
                  <b>
                    <span style={{ color: "red" }}>Note:</span>
                    <br />
                    You can preview the job page once saved.
                    <br />
                    You can edit the job only for 24 hours after creating it.
                  </b>
                </div>
                <div>
                  <button type="submit" className="btn btn-success me-2">
                    <i className="fa fa-save"></i> Save
                  </button>
                  <button
                    type="button"
                    onClick={() => setAddModal(false)}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal fade"
        size="lg"
        show={updateModel}
        onHide={setUpdateModal}
      >
        <div role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Job</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setUpdateModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <form onSubmit={handleUpdate}>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="mb-3 form-group">
                          <label htmlFor="job_title">
                            Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_title"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_title: e.target.value,
                              })
                            }
                            className="form-control"
                            defaultValue={editData?.job_title}
                            type="text"
                            name="job_title"
                            required=""
                          />
                          {errors?.job_title !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_title}
                            </div>
                          )}
                        </div>
                        {/* <div className="mb-3 form-group">
                          <label htmlFor="company_id">
                            Company<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="company_id"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                company_id: e.target.value,
                              })
                            }
                            defaultValue={editData?.company_id}
                            className="form-control"
                            type="text"
                            name="company_id"
                            required=""
                          />
                          {errors?.company_id !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.company_id}
                            </div>
                          )}
                        </div> */}
                        <div className="row">
                          <label>
                            Experience <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-md-6">
                            <div className="input-group mb-3 form-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Min
                              </span>
                              <select
                                id="min_experience"
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    min_experience: e.target.value,
                                  })
                                }
                                className="form-select"
                                defaultValue={editData?.min_experience}
                                type="text"
                                name="min_experience"
                              >
                                {selectOptions()}
                              </select>
                              {errors?.min_experience !== undefined && (
                                <div className="invalid-feedback d-block">
                                  {errors?.min_experience}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-group mb-3 form-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Max
                              </span>
                              <select
                                id="max_experience"
                                onChange={(e) =>
                                  setInputData({
                                    ...inputData,
                                    max_experience: e.target.value,
                                  })
                                }
                                defaultValue={editData?.max_experience}
                                className="form-select"
                                type="text"
                                name="max_experience"
                              >
                                {selectOptions()}
                              </select>
                              {errors?.max_experience !== undefined && (
                                <div className="invalid-feedback d-block">
                                  {errors?.max_experience}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="locations">
                            Locations<span style={{ color: "red" }}>*</span>{" "}
                            <sub>
                              (Please enter a comma-separated list of cities)
                            </sub>
                          </label>
                          <MultiSelect
                            multiple={true}
                            options={locations}
                            onValueSelect={handleMultiSelect}
                            name="job_locations"
                            selected={selectedLocations}
                          />
                          {errors?.job_locations !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_locations}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="skills">
                            Skills<span style={{ color: "red" }}>*</span>{" "}
                            <sub>
                              (Please enter a comma-separated list of skills)
                            </sub>
                          </label>

                          <MultiSelect
                            multiple={true}
                            options={skills}
                            onValueSelect={handleMultiSelect}
                            name="job_skills"
                            selected={selectedSkills}
                          />
                          {errors?.job_skills !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_skills}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_position">
                            Position<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_position"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_position: e.target.value,
                              })
                            }
                            defaultValue={editData?.job_position}
                            className="form-control"
                            type="text"
                            name="job_position"
                            required=""
                          />
                          {errors?.job_position !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_position}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_ctc">
                            CTC<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="job_ctc"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_ctc: e.target.value,
                              })
                            }
                            defaultValue={editData?.job_ctc}
                            className="form-control"
                            type="text"
                            name="job_ctc"
                            required=""
                          />
                          {errors?.job_ctc !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_ctc}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_details">
                            Summary<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <SunEditor
                            getSunEditorInstance={getUpdtaeSunEditorInstance}
                            setOptions={{
                              height: 450,
                              buttonList: [
                                ["undo", "redo"],
                                ["bold", "italic", "underline"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                ["removeFormat"],
                                "/",
                                [
                                  "align",
                                  "horizontalRule",
                                  "list",
                                  "lineHeight",
                                ],
                                ["link", "image", "video"],
                                ["fullScreen", "showBlocks", "codeView"],
                              ],
                            }}
                            onChange={(content) => {
                              setInputData({
                                ...inputData,
                                job_details: content,
                              });
                            }}

                            //setContents={editData?.job_details}
                          /> */}
                          {editData?.job_details && (
                            <SuneditorComponent
                              setEditorValue={onSaveEditorValue}
                              fieldName="job_details"
                              ipValue={editData?.job_details}
                            />
                          )}
                          {/* <CKEditor
                            config={{
                              extraPlugins: [
                                function (editor) {
                                  editor.plugins.get(
                                    "FileRepository"
                                  ).createUploadAdapter = (loader) => {
                                    return new uploadAdapter(loader);
                                  };
                                },
                              ],
                            }}
                            editor={ClassicEditor}
                            data={editData?.job_details}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={(editor) => {
                              //editor.config.initialData = editData?.job_details;
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setInputData({
                                ...inputData,
                                job_details: data,
                              });
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          /> */}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_sector">
                            Job Sector<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              //value=""
                              id="tech"
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  job_sector: 1,
                                })
                              }
                              name="job_sector"
                              defaultChecked={
                                editData?.job_sector === 1 ? true : false
                              }
                            />
                            <label className="form-check-label" htmlFor="tech">
                              Tech
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  job_sector: 0,
                                })
                              }
                              id="non-tech"
                              name="job_sector"
                              defaultChecked={
                                editData?.job_sector === 0 ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="non-tech"
                            >
                              Non-tech
                            </label>
                          </div>
                          {errors?.job_sector !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_sector}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="job_types">
                            Job Type<span style={{ color: "red" }}>*</span>
                            <sub>
                              (Please enter job types. Example: Full-time,
                              part-time, work from home)
                            </sub>
                          </label>
                          {/* <input
                            id="job_types"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                job_types: e.target.value,
                              })
                            }
                            className="form-control"
                            type="text"
                            name="job_types"
                            defaultValue={editData?.job_types}
                            required=""
                          /> */}
                          <MultiSelect
                            multiple={true}
                            onValueSelect={handleMultiSelect}
                            name="job_types"
                            options={jobTypes}
                            selected={selectedJobTypes}
                          />
                          {errors?.job_types !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.job_types}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="application_deadline">
                            Application Deadline
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="form-group">
                            <DatePicker
                              id="application_deadline"
                              selected={
                                inputData?.application_deadline !== '0000-00-00'
                                  ? new Date(inputData?.application_deadline)
                                  : new Date()
                              }
                              onChange={(date) =>
                                setInputData({
                                  ...inputData,
                                  application_deadline: date,
                                })
                              }
                              name="application_deadline"
                              className="form-control"
                              dateFormat="Y-MM-dd"
                              minDate={new Date()}
                            />
                          </div>
                          {errors?.application_deadline !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.application_deadline}
                            </div>
                          )}
                        </div>
                        <div className="mb-3 form-group">
                          <label htmlFor="profile_image">
                            Please Upload Your Company Logo (284*117)
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            id="profile_image"
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                profile_image: e.target.files[0].arrayBuffer,
                              })
                            }
                            className="form-control"
                            type="file"
                            name="profile_image"
                            required=""
                          />
                          {errors?.profile_image !== undefined && (
                            <div className="invalid-feedback d-block">
                              {errors?.profile_image}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-success">
                  <i className="fa fa-save"></i> Update
                </button>
                <button
                  type="button"
                  onClick={() => setUpdateModal(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
